import { RoleName } from '@gonfalon/permissions';

export enum MemberInstructionKind {
  REPLACE_MEMBERS_ROLES = 'replaceMembersRoles',
  REPLACE_ALL_MEMBERS_ROLES = 'replaceAllMembersRoles',
}

export type ReplaceMembersRolesInstruction = {
  kind: MemberInstructionKind.REPLACE_MEMBERS_ROLES;
  value: RoleName;
  memberIDs: string[];
};

export type ReplaceAllMembersRolesInstruction = {
  kind: MemberInstructionKind.REPLACE_ALL_MEMBERS_ROLES;
  value: RoleName;
  ignoredMemberIDs?: string[];
};

export type MemberInstruction = ReplaceMembersRolesInstruction | ReplaceAllMembersRolesInstruction; // union more instructions here
