import { RoleName } from '@gonfalon/permissions';

import { MemberInstructionKind, ReplaceAllMembersRolesInstruction, ReplaceMembersRolesInstruction } from './types';

export function makeReplaceMembersRolesInstruction(
  memberIDs: string[],
  role: RoleName,
): ReplaceMembersRolesInstruction {
  return {
    kind: MemberInstructionKind.REPLACE_MEMBERS_ROLES,
    value: role,
    memberIDs,
  };
}

export function makeReplaceAllMembersRolesInstruction(role: RoleName, ignoredMemberIDs?: string[]) {
  const instruction: ReplaceAllMembersRolesInstruction = {
    kind: MemberInstructionKind.REPLACE_ALL_MEMBERS_ROLES,
    value: role,
  };

  if (ignoredMemberIDs && ignoredMemberIDs.length > 0) {
    instruction.ignoredMemberIDs = ignoredMemberIDs;
  }

  return instruction;
}
