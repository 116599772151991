import { ReactElement, useState } from 'react';
import { makeReplaceAllMembersRolesInstruction, makeReplaceMembersRolesInstruction } from '@gonfalon/members';
import { isRESTAPIError, usePatchMembers } from '@gonfalon/rest-api';
import { capitalize, pluralize } from '@gonfalon/strings';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTrigger,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  ProgressBar,
  Radio,
  RadioGroup,
  snackbarQueue,
  toastQueue,
} from '@launchpad-ui/components';
import { Box, ButtonProps, IconButtonProps } from '@launchpad-ui/core';

import { RoleName } from '../internal/types';
import { formattedRoleName, simpleRoles } from '../roles';

export type AssignLegacyRoleModalProps = {
  trigger?: ReactElement<ButtonProps | IconButtonProps>;
  memberIDs: string[];
  asChild?: boolean;
  onClose?: () => void;
  isAllMembersSelected?: boolean;
  // use external count instead of memberIDs.length because the external count takes into account selecting "all"
  selectedMemberCount: number;
};

export function AssignLegacyRoleModal({
  trigger: triggerProp,
  memberIDs,
  asChild,
  onClose,
  isAllMembersSelected,
  selectedMemberCount,
}: AssignLegacyRoleModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RoleName | null>(null);

  const memberWord = pluralize('member', selectedMemberCount);
  const roleWord = pluralize('role', selectedMemberCount);

  const { mutate: patchMembers, isPending: isPatchingMembers } = usePatchMembers();
  const trigger = triggerProp ?? <Button variant="primary">Replace {roleWord}</Button>;

  const handleOpenChange = (nextIsOpen: boolean) => {
    if (onClose) {
      onClose();
      return;
    }
    setIsOpen(nextIsOpen);
  };

  const handleSubmit = () => {
    if (!selectedRole) {
      return;
    }

    const instruction = isAllMembersSelected
      ? makeReplaceAllMembersRolesInstruction(selectedRole, memberIDs)
      : makeReplaceMembersRolesInstruction(memberIDs, selectedRole);

    patchMembers(
      {
        body: {
          instructions: [instruction],
        },
      },
      {
        onSuccess: () => {
          toastQueue.add({
            title: `${capitalize(memberWord)} updated`,
            status: 'success',
          });
          handleOpenChange(false);
        },
        onError: (err: Error) => {
          snackbarQueue.add({
            description: isRESTAPIError(err) ? err?.message : `Failed to update member ${roleWord}. Try again later.`,
            status: 'error',
          });
        },
      },
    );
  };

  const modal = (
    <Modal data-test-id="modal">
      <Dialog>
        <div slot="header">
          <Heading slot="title">
            Edit legacy role for {selectedMemberCount} {pluralize('member', selectedMemberCount)}
          </Heading>
          <IconButton
            aria-label="close modal"
            icon="cancel"
            variant="minimal"
            onPress={() => handleOpenChange(false)}
          />
        </div>
        <Box slot="body" display="grid" gap="$500">
          <p>
            Select a legacy role to replace the existing role for {memberIDs.length === 1 ? 'this' : 'these'}{' '}
            {memberWord}.
          </p>
          <RadioGroup
            value={selectedRole}
            onChange={(v) => setSelectedRole(v as RoleName)}
            aria-label="Select a legacy role"
          >
            {simpleRoles
              .filter((r) => r !== RoleName.OWNER)
              .map((r) => (
                <Radio value={r} key={r}>
                  {formattedRoleName(r)} {r !== RoleName.NO_ACCESS ? '(legacy)' : ''}
                </Radio>
              ))}
          </RadioGroup>
        </Box>
        <div slot="footer">
          <ButtonGroup>
            <Button variant="default" onPress={() => handleOpenChange(false)}>
              Cancel
            </Button>
            <Button variant="primary" onPress={handleSubmit} isDisabled={!selectedRole || isPatchingMembers}>
              {isPatchingMembers ? <ProgressBar isIndeterminate size="small" /> : `Replace ${roleWord}`}
            </Button>
          </ButtonGroup>
        </div>
      </Dialog>
    </Modal>
  );

  if (asChild) {
    return (
      <ModalOverlay isOpen onOpenChange={handleOpenChange}>
        {modal}
      </ModalOverlay>
    );
  }

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      {trigger}
      <ModalOverlay>{modal}</ModalOverlay>
    </DialogTrigger>
  );
}
